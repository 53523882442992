import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { IonIcon, IonItemGroup, useIonViewDidLeave } from "@ionic/react";

import LoginHeader from "../../organisms/LoginHeader";
import CategoryForm from "../../molecules/CategoryForm";
import ExperienceSelectForm from "../../molecules/ExperienceSelectForm";
import {
  ICategory,
  IClass,
  IStudentGearBox,
  ITeacher,
  IRealizationImage,
  IGearTheme,
  IRealizationCreateParams,
  IRealizationFeedback,
  IStudentRealizationFeedback,
} from "../../state";
import { isImageFile } from "../../libs/Util";
import realizationIcon from "../../assets/icons/realization.svg";
import convertIcon from "../../assets/icons/convert.svg";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import RealizationTextArea, {
  CONTENT_MAX_LENGTH,
} from "../../atoms/RealizationTextArea";
import RealizationThumbnailImage from "../../atoms/RealizationThumbnailImage";
import WordCounter from "../../atoms/WordCounter";
import CommentModal from "../../atoms/CommentModal";
import ImageDetailModal from "../../molecules/ImageDetailModal";
import ModalConfirm from "../../molecules/ModalConfirm";
import ThemeHeader from "../../molecules/ThemeHeader";
import Toast, { TToastType } from "../../molecules/Toast";
import TargetGroup, { ETargetSwitch } from "../../organisms/TargetGroup";
import {
  Context,
  INPUT_REALIZATION_CONTENT_FAILURE_MESSAGE,
  SELECT_REALIZATION_BOX_FAILURE_MESSAGE,
  SELECT_REALIZATION_CLASS_FAILURE_MESSAGE,
  SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE,
} from "../../store/student";

import styles from "./RealizationForm.module.scss";

export type RealizationFormProps = {
  pathname: string;
  success_message: string;
  categories: ICategory[];
  teachers: ITeacher[];
  classes: IClass[];
  gearBoxes: IStudentGearBox[];
  calling: boolean;
  unreadNotiCount: number;
  stock_c_to_c_enabled: boolean;
  pulse_survey_enabled: boolean;
  realization_feedback_creatable: boolean;
  student_realization_feedbacks: IStudentRealizationFeedback;
  gearTheme: IGearTheme | null;
  createRealization(
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
    geartheme_id?: number,
  ): void;
  createCategory(category: { name: string; color: string }): void;
  createRealizationFeedbacks: () => void;
  updateReadAtToRealizationFeedback: (realization_feedback_id: number) => void;
};

const INITIAL_STATE = {
  content: "",
  color: "",
  category_ids: [],
  experience_nos: [],
  selected_teacher_ids: [],
  selected_class_ids: [],
  image: undefined,
  geartheme_id: undefined,
  toast: null,
  showCategoryForm: false,
  showSubmitForm: false,
  showShareForm: false,
  showBoxForm: false,
  showImageInvalid: false,
  showImageExceedsSize: false,
  showImageDetail: false,
  showRealizationFeedback: false,
  disabledFeedbackBtn: false,
  selected_box_id: null,
  targetSwitch: ETargetSwitch.ONLY_ME,
  realization_feedback: undefined,
};

const RealizationForm = (props: RealizationFormProps) => {
  const history = useHistory();
  const realizationImgInputRef = React.useRef<HTMLInputElement>(null);
  const { dispatch, contextState } = React.useContext(Context);
  const [isShowModalConfirm, setIsShowModalConfirm] = React.useState(false);
  const [nextLocation, setNextLocation] = React.useState("/realization");
  const [state, setState] = React.useState<{
    content: string;
    color: string;
    category_ids: number[];
    experience_nos: number[];
    selected_teacher_ids: number[];
    selected_class_ids: number[];
    selected_box_id: number | null;
    image?: IRealizationImage;
    toast: {
      type: TToastType;
      message: string;
    } | null;
    showCategoryForm: boolean;
    showSubmitForm: boolean;
    showShareForm: boolean;
    showBoxForm: boolean;
    showImageInvalid: boolean;
    showImageExceedsSize: boolean;
    showImageDetail: boolean;
    showRealizationFeedback: boolean;
    targetSwitch: ETargetSwitch;
    geartheme_id?: number;
    disabledFeedbackBtn: boolean;
    realization_feedback: IRealizationFeedback | undefined;
  }>(INITIAL_STATE);

  const update = (args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  };

  React.useEffect(() => {
    const blockOfHistoryPop = history.block((location, _action) => {
      const regex = /\/realizations\/category\/\d+/;
      const isCurrentPath = nextLocation === "/realization";
      const isCreateStockPath = location.pathname.match(regex);

      // 入力中のストックが存在し、ストック作成以外の動線に遷移しようとした場合にモーダルを表示
      if (state.content.length > 0 && isCurrentPath && !isCreateStockPath) {
        setNextLocation(location.pathname);
        setIsShowModalConfirm(true);
        return false;
      } else {
        setNextLocation("/realization");
      }
    });

    return () => blockOfHistoryPop();
  }, [history, state.content, nextLocation]);

  const [errorMessage, setErrorMessage] = React.useState("");

  useIonViewDidLeave(() => {
    update(INITIAL_STATE);
  });

  React.useEffect(() => {
    const realization_feedback =
      props.student_realization_feedbacks?.realization_feedbacks[0];
    if (!realization_feedback) return;
    setState(s => ({
      ...s,
      realization_feedback,
    }));
    if (realization_feedback.read_at) return;
    props.updateReadAtToRealizationFeedback(realization_feedback.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.student_realization_feedbacks]);

  React.useEffect(() => {
    if (contextState.error) {
      setErrorMessage(contextState.error);
    }
  }, [dispatch, contextState.error]);

  const removeImg = useCallback((e?) => {
    if (e) {
      e.stopPropagation();
    }
    realizationImgInputRef.current &&
      (realizationImgInputRef.current.value = "");
    setState(s => ({ ...s, image: undefined }));
  }, []);

  const uploadImg = useCallback(
    e => {
      e.persist();
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      if (!isImageFile(file)) {
        removeImg();
        setState(s => ({ ...s, showImageInvalid: true }));
        return;
      }
      const fileSizeByMB = file.size / 1024 / 1024;
      if (fileSizeByMB > 5) {
        removeImg();
        setState(s => ({ ...s, showImageExceedsSize: true }));
        return;
      }
      setState(s => ({
        ...s,
        image: {
          name: file.name,
          url: URL.createObjectURL(file),
          blob: file,
        },
      }));
    },
    [removeImg],
  );

  const setBoxTemplate = useCallback(
    text => {
      setState({ ...state, content: text });
    },
    [state],
  );

  const onSubmit = (kind: "roots" | "will") => {
    if (!state.content) {
      update({
        toast: {
          type: "danger",
          message: INPUT_REALIZATION_CONTENT_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (state.targetSwitch === ETargetSwitch.BOX && !state.selected_box_id) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_BOX_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (
      state.targetSwitch === ETargetSwitch.GROUP &&
      !state.selected_class_ids.length
    ) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_CLASS_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (
      state.targetSwitch === ETargetSwitch.TEACHER &&
      !state.selected_teacher_ids.length
    ) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE,
        },
      });
      return;
    }
    const selectedBox = state.selected_box_id
      ? props.gearBoxes.find(box => box.id === state.selected_box_id)
      : undefined;
    props.createRealization(
      {
        category_ids: state.category_ids,
        experience_nos: state.experience_nos,
        content: state.content,
        submitted: state.selected_teacher_ids.length > 0,
        shared: state.selected_class_ids.length > 0 || !!props.gearTheme,
        kind,
      },
      selectedBox ? [selectedBox.teacher.id] : state.selected_teacher_ids,
      selectedBox
        ? selectedBox.klasses.map(cls => cls.id)
        : state.selected_class_ids,
      state.selected_box_id,
      state.image,
      props.gearTheme?.id,
    );
  };

  return (
    <IonItemGroup className={styles.wrapper}>
      <Toast
        type={state.toast?.type}
        showToast={!!state.toast}
        onClose={() => update({ toast: null })}
        message={state.toast?.message ?? ""}
      />
      <ModalConfirm
        isOpen={state.showImageInvalid}
        message={`無効なファイル形式です。\njpg/jpeg/png のみアップロード可能です。`}
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => setState(s => ({ ...s, showImageInvalid: false })),
          },
          {
            title: "OK",
            type: "Success",
            action: () => setState(s => ({ ...s, showImageInvalid: false })),
          },
        ]}
      />
      <ModalConfirm
        isOpen={state.showImageExceedsSize}
        message={`アップロードに失敗しました。\nファイルサイズを5MB以下にしてください。`}
        buttons={[
          {
            title: "OK",
            type: "None",
            action: () =>
              setState(s => ({ ...s, showImageExceedsSize: false })),
          },
        ]}
      />
      <ModalConfirm
        isOpen={isShowModalConfirm}
        message={`ページを遷移すると、現在入力中の文章が消えてしまいます。移動してもよろしいですか？`}
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => {
              // モーダル表示のタイミングでnextLocationが更新されてしまうため、デフォルトに戻す
              setNextLocation("/realization");
              setIsShowModalConfirm(false);
            },
          },
          {
            title: "OK",
            type: "Success",
            action: () => {
              setIsShowModalConfirm(false);
              history.push(nextLocation);
              // history.pushでDOMが更新されないケースがあるため暫定対処
              window.location.reload();
            },
          },
        ]}
      />
      {state.image && (
        <ImageDetailModal
          show={state.showImageDetail}
          imageUrl={state.image.url}
          imageName={state.image.name}
          onClose={() => setState(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <CommentModal
        isOpen={state.showRealizationFeedback}
        onClickBackdrop={() =>
          setState(s => ({ ...s, showRealizationFeedback: false }))
        }
      >
        <div className={styles.feedbackModal}>
          <span
            className={styles.close}
            onClick={() =>
              setState(s => ({ ...s, showRealizationFeedback: false }))
            }
          />
          {props.calling ? (
            <p className={styles.callingMessage}>フィードバック作成中</p>
          ) : errorMessage.length > 0 ? (
            errorMessage
          ) : (
            <div className={styles.inner}>
              <p className={styles.title}>
                エナジード先生から
                {props.student_realization_feedbacks.last_name}{" "}
                {props.student_realization_feedbacks.first_name}
                さんへのフィードバック
              </p>
              <p className={styles.content}>
                {state.realization_feedback?.content}
              </p>
              <p className={styles.date}>
                {state.realization_feedback?.created_at}
              </p>
            </div>
          )}
        </div>
      </CommentModal>
      <LoginHeader
        title="ストック"
        unreadNotiCount={props.unreadNotiCount}
        updateTarget={state.content}
      />
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          {props.gearTheme && (
            <ThemeHeader geartheme={props.gearTheme} hideCreate />
          )}
          <div className={styles.inputArea}>
            <div className={styles.textAreaWrapper}>
              <RealizationTextArea
                content={state.content}
                onChange={value => setState({ ...state, content: value })}
              />
            </div>
            <div className={styles.imgInputWrapper}>
              {state.image ? (
                <div className={styles.realizationImg}>
                  <RealizationThumbnailImage
                    alt="Upload Image"
                    src={state.image.url}
                    onClick={e => {
                      e.stopPropagation();
                      setState(s => ({
                        ...s,
                        showImageDetail: true,
                      }));
                    }}
                    isEdit={true}
                  />
                  <button onClick={removeImg}>✕</button>
                </div>
              ) : (
                <>
                  <input
                    ref={realizationImgInputRef}
                    accept=".png,.jpg,.jpeg"
                    type="file"
                    onChange={uploadImg}
                  />
                  <ImageIcon
                    onClick={() => realizationImgInputRef.current?.click()}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.counter}>
            <WordCounter
              maxLength={CONTENT_MAX_LENGTH}
              targetLength={state.content.length}
            />
          </div>
          <CategoryForm
            pathname={props.pathname}
            success_message={props.success_message}
            calling={props.calling}
            isOpen={state.showCategoryForm}
            categories={props.categories}
            selectedCategoryIDs={state.category_ids}
            update={update}
            createCategory={props.createCategory}
          />
          {!props.gearTheme && (
            <TargetGroup
              gearboxes={props.gearBoxes}
              teachers={props.teachers}
              classes={props.classes}
              stock_c_to_c_enabled={props.stock_c_to_c_enabled}
              showSubmitForm={state.showSubmitForm}
              showShareForm={state.showShareForm}
              showBoxForm={state.showBoxForm}
              targetSwitch={state.targetSwitch}
              selected_box_id={state.selected_box_id}
              selected_teacher_ids={state.selected_teacher_ids}
              selected_class_ids={state.selected_class_ids}
              setBoxTemplate={setBoxTemplate}
              update={update}
            />
          )}
          <ExperienceSelectForm
            checkedExperienceNos={state.experience_nos}
            update={update}
          />
          {props.realization_feedback_creatable && (
            <div className={styles.feedbackButton}>
              <p className={styles.text}>
                エナジード先生からのフィードバックが受けられます
              </p>
              <button
                className={styles.button}
                onClick={() => {
                  setState(s => ({
                    ...s,
                    disabledFeedbackBtn: true,
                  }));
                  props.createRealizationFeedbacks();
                  setState(s => ({ ...s, showRealizationFeedback: true }));
                }}
                disabled={state.disabledFeedbackBtn}
              >
                フィードバックを見る
              </button>
            </div>
          )}
        </div>
        <div className={styles.wrapperButton}>
          <button
            className={styles.createRealization}
            onClick={() => onSubmit("roots")}
          >
            <IonIcon
              icon={realizationIcon}
              slot="start"
              className={styles.icon}
            />
            <span>
              ROOTSを
              <br />
              ストック
            </span>
          </button>
          <button
            className={styles.createConversion}
            onClick={() => onSubmit("will")}
          >
            <IonIcon icon={convertIcon} slot="start" className={styles.icon} />
            <span>
              WILLを
              <br />
              ストック
            </span>
          </button>
        </div>
      </div>
    </IonItemGroup>
  );
};

export default RealizationForm;
