import {
  createGesture,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  personCircle,
  ellipsisHorizontal,
  chevronBackOutline as backIcon,
} from "ionicons/icons";
import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { parseLocationQueryParams, setScrollTop } from "../../libs/Util";
import { IComment, IRealization, ReactableType } from "../../state";
import CommentList from "../../organisms/CommentList";
import ReportForm from "../../organisms/Forms/ReportForm";
import CommentModal from "../../atoms/CommentModal";
import ImageDetailModal from "../../molecules/ImageDetailModal";
import Toast from "../../molecules/Toast";
import RealizationContent from "../../molecules/RealizationContent";
import StampSelector from "../../molecules/StampSelector";
import type { Stamp } from "../../libs/stamp";
import { Context, setSidebarSwipe } from "../../store/student";

import styles from "./TimelineEntryDetailModal.module.scss";

const TEXT_AREA_MIN_HEIGHT = 22;
export interface TimelineEntryDetailModalProps {
  realization: IRealization;
  comments: IComment[];
  current_id: number;
  realization_klass_id?: number;
  showDetail: boolean;
  isTimelinePast?: boolean;
  isOtherStudent: boolean;
  hideReactions?: boolean;
  onClose: () => void;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  createReport: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (comment_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  clearOldComments: () => void;
  addDerivative: () => void;
  createStampReaction?: (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => void;
}

const TimelineEntryDetailModal = (props: TimelineEntryDetailModalProps) => {
  const addFavorite = props.addFavorite;
  const removeFavorite = props.removeFavorite;
  const listRef = React.useRef<HTMLDivElement>(null);
  const commentRef = React.useRef(null);
  const textRef = React.useRef<HTMLTextAreaElement>(null);
  const { dispatch } = useContext(Context);
  const history = useHistory();
  const contentRef = React.useRef<HTMLDivElement>(null);

  const [values, updateValues] = React.useState({
    content: "",
    targetRealizationID: 0,
    showToast: false,
    showImageDetail: false,
    editTargetID: 0,
  });

  const [commentHeight, setCommentHeight] =
    React.useState(TEXT_AREA_MIN_HEIGHT);

  const update = (args: { [key: string]: any }) => {
    updateValues({ ...values, ...args });
  };

  const derivable = React.useMemo(
    () =>
      props.isOtherStudent &&
      !props.isTimelinePast &&
      props.realization.gearbox?.name_status !== "unpublish" &&
      !props.realization.geartheme,
    [props.realization, props.isOtherStudent, props.isTimelinePast],
  );

  const handleCommentButtonClick = () => {
    if (values.content === "") {
      updateValues({ ...values, showToast: true });
      return;
    }
    if (values.editTargetID > 0) {
      props.updateComment(values.editTargetID, values.content);
    } else {
      props.createComment(props.realization.id, values.content);
    }

    updateValues({ ...values, content: "", editTargetID: 0 });
    setTimeout(() => {
      listRef.current?.scrollTo(1, 10000);
    }, 500);
  };

  const handleStampReaction = (stamp: Stamp) => {
    props.createStampReaction &&
      props.createStampReaction(props.realization.id, "comment", stamp);
    setTimeout(() => {
      listRef.current?.scrollTo(1, 10000);
    }, 500);
  };

  useIonViewDidEnter(() => {
    if (parseLocationQueryParams()["comment"]) {
      setTimeout(() => {
        setScrollTop(1000);
        listRef.current?.scrollTo(1, 10000);
        textRef.current?.focus();
      }, 100);
    }
  });

  useEffect(() => {
    if (
      history.location.pathname === "/timeline" &&
      history.location.search !== `?/${props.realization_klass_id}`
    ) {
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    if (commentRef.current) {
      const dom: HTMLTextAreaElement = commentRef.current as any;
      if (dom) {
        dom.scrollTop = 9999;
        setCommentHeight(
          Math.min(
            Math.max(TEXT_AREA_MIN_HEIGHT, dom.scrollTop),
            window.innerHeight * 0.3,
          ),
        );
      }
    }
    return;
  }, [values.content]);

  useEffect(() => {
    const swipeElement = contentRef.current;
    const SWIPE_THRESHOLD = 50;
    dispatch(setSidebarSwipe(false));
    if (swipeElement) {
      const gesture = createGesture({
        el: swipeElement,
        gestureName: "swipe-horizontal",
        direction: "x",
        canStart: detail => {
          const target = detail.event.target as HTMLElement;
          // 'disable-gesture' クラスを持つ要素内ではジェスチャーを開始しない
          // 該当のテキストのみ適応するため子コンポーネントで 'disable-gesture' クラスを適用しています
          return !target.closest(".disable-gesture");
        },
        onMove: detail => {
          const { deltaX } = detail;
          if (deltaX > SWIPE_THRESHOLD) {
            history.push({
              pathname: history.location.pathname,
              search: "",
            });
          }
        },
      });

      gesture.enable();

      return () => {
        gesture.destroy();
        dispatch(setSidebarSwipe(true));
      };
    }
  }, [dispatch, history]);

  const toggleFavoriteFunc = React.useCallback(() => {
    if (props.realization.is_favorite) {
      removeFavorite && removeFavorite(props.realization.id);
    } else {
      addFavorite && addFavorite(props.realization.id);
    }
  }, [props.realization, addFavorite, removeFavorite]);

  return (
    <CommentModal
      isOpen={props.showDetail}
      onClickBackdrop={() => {
        props.clearOldComments();
        props.onClose();
      }}
    >
      {props.realization.image && (
        <ImageDetailModal
          show={values.showImageDetail}
          imageUrl={props.realization.image.url}
          imageName={props.realization.image.name}
          onClose={() => updateValues(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <div ref={contentRef}>
        <IonContent
          className={clsx(
            styles.wrapper,
            props.isTimelinePast && styles.wrapper__past,
          )}
        >
          <div className={styles.contentComment} ref={listRef}>
            <ReportForm
              isOpen={values.targetRealizationID !== 0}
              close={() => updateValues({ ...values, targetRealizationID: 0 })}
              report={() => {
                props.createReport &&
                  props.createReport({
                    realization_id: values.targetRealizationID,
                  });
                updateValues({ ...values, targetRealizationID: 0 });
              }}
            />
            <Toast
              type="danger"
              showToast={values.showToast}
              onClose={() => updateValues({ ...values, showToast: false })}
              message="コメントが入力されていません。"
            />
            <IonHeader className={styles.header} mode="ios">
              <IonToolbar className={styles.toolBar}>
                <IonTitle className={styles.title}>詳細</IonTitle>
                <IonButtons slot="start">
                  <IonButton
                    onClick={() => {
                      props.clearOldComments();
                      props.onClose();
                    }}
                  >
                    <IonIcon icon={backIcon} color="light" />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <div
              className={clsx(
                styles.realizationItem,
                props.isTimelinePast && styles.realizationItem__past,
              )}
            >
              <div className={styles.avatarArea}>
                {props.realization.student?.photo?.url ||
                props.realization.photo?.url ? (
                  <IonAvatar
                    slot="start"
                    color="light"
                    className={styles.avatar}
                  >
                    <img
                      alt="プロフィール画像"
                      src={
                        props.realization.student?.photo?.url ||
                        props.realization.photo?.url
                      }
                    />
                  </IonAvatar>
                ) : (
                  <IonIcon
                    className={styles.avatar}
                    icon={personCircle}
                    slot="start"
                    color="light"
                  />
                )}
              </div>
              <IonGrid>
                <IonRow className={styles.row}>
                  <IonCol
                    size={
                      props.current_id === props.realization.student_id
                        ? "12"
                        : "10"
                    }
                  >
                    <IonLabel color="light">
                      <span className={styles.name}>
                        {props.realization.student?.full_name ?? ""}
                      </span>
                    </IonLabel>
                  </IonCol>
                  {props.current_id !== props.realization.student_id && (
                    <IonCol size="2">
                      <IonIcon
                        icon={ellipsisHorizontal}
                        className={styles.reportIcon}
                        onClick={() =>
                          updateValues({
                            ...values,
                            targetRealizationID: props.realization.id,
                          })
                        }
                      />
                    </IonCol>
                  )}
                  <IonCol size="12" className={styles.contentWrapper}>
                    <RealizationContent
                      realization={props.realization}
                      hideReactions={props.hideReactions}
                      disableGesture={true}
                      isDetail
                      onClickThumbnailImage={() => {
                        updateValues(s => ({
                          ...s,
                          showImageDetail: true,
                        }));
                      }}
                      onClickFavorite={
                        !props.isTimelinePast ? toggleFavoriteFunc : undefined
                      }
                      onClickDerivative={
                        derivable ? props.addDerivative : undefined
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            {!props.hideReactions && (
              <div className={styles.commentList}>
                <CommentList
                  current_student_id={props.current_id}
                  createReport={props.createReport}
                  delete={props.deleteComment}
                  comments={props.comments}
                  update={update}
                />
              </div>
            )}
          </div>
          {!props.hideReactions && (!props.isTimelinePast || values.content) ? (
            <div className={styles.container}>
              <div className={styles.inputArea} slot="fixed">
                <textarea
                  placeholder="コメントする"
                  style={{
                    height: commentHeight + 18,
                  }}
                  ref={textRef}
                  className={styles.input}
                  value={values.content}
                  onChange={e => {
                    updateValues({ ...values, content: e.target.value });
                  }}
                />
                <textarea
                  ref={commentRef}
                  style={{
                    position: "absolute",
                    left: 9999,
                    height: 0,
                    zIndex: -1,
                    width: "100%",
                  }}
                  className={styles.input}
                  defaultValue={values.content}
                  rows={1}
                  tabIndex={-1}
                />
                {values.editTargetID > 0 && (
                  <IonButton
                    className={styles.cancelButton}
                    onClick={() => {
                      updateValues({
                        ...values,
                        content: "",
                        editTargetID: 0,
                      });
                    }}
                  >
                    キャンセル
                  </IonButton>
                )}
                {values.editTargetID === 0 && (
                  <StampSelector layout="flat" onSelect={handleStampReaction} />
                )}
                <IonButton
                  className={styles.commentButton}
                  onClick={handleCommentButtonClick}
                >
                  {values.editTargetID > 0 ? "更新" : "投稿"}
                </IonButton>
              </div>
            </div>
          ) : null}
        </IonContent>
      </div>
    </CommentModal>
  );
};

export default TimelineEntryDetailModal;
