import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import { isSameDay } from "date-fns";
import { PieChart, Pie, Cell } from "recharts";

import common from "../common.module.scss";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import ModalConfirm from "../../../molecules/ModalConfirm";
import LoginHeader from "../../../organisms/LoginHeader";
import {
  Context,
  fetchAssessment,
  submitExperience,
  assessmentListPath,
  assessmentReferencePath,
} from "../../../store/student";
import { ASSESSMENT_STATUS } from "../../../constants/assessment-content";

import styles from "./AssessmentDescriptionPage.module.scss";

const AssessmentDescriptionPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const currentAssessment = contextState.assessment;
  const [calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();

  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [scoreList, setScoreList] = React.useState({
    experience_score_1: null,
    experience_score_2: null,
    experience_score_3: null,
    experience_score_4: null,
    experience_score_5: null,
    experience_score_6: null,
    experience_score_7: null,
  });

  React.useEffect(() => {
    const scoreMap = contextState.assessment.assessment_experiences.map(exp => [
      `experience_score_${exp.experience_no}`,
      exp.score,
    ]);
    setScoreList(prevScoreList => {
      return { ...prevScoreList, ...Object.fromEntries(scoreMap) };
    });
  }, [contextState.assessment]);

  const assessmentStatusLength = ASSESSMENT_STATUS.length;

  const answeredScoreLength = React.useMemo(() => {
    return Object.values(scoreList).filter(value => value !== null);
  }, [scoreList]).length;

  const unansweredScoreRate = [
    { name: "allocated", value: answeredScoreLength, colorCode: "#3EBB9D" },
    {
      name: "unallocated",
      value: assessmentStatusLength - answeredScoreLength,
      colorCode: "#243140",
    },
  ];

  const currentTermNo = React.useMemo(() => {
    return contextState.student.max_term_no || 0;
  }, [contextState.student.max_term_no]);

  const hasSameDayAssessment = React.useMemo(() => {
    return isSameDay(new Date(currentAssessment.created_at), new Date());
  }, [currentAssessment]);

  const handleSubmitExperience = React.useCallback(() => {
    if (calling) return;

    dispatch(
      peep(
        submitExperience({
          experience_score_1: null,
          experience_score_2: null,
          experience_score_3: null,
          experience_score_4: null,
          experience_score_5: null,
          experience_score_6: null,
          experience_score_7: null,
          reasons: [],
          next_actions: [],
          term_no: currentTermNo + 1,
        }),
      ),
    );
  }, [calling, dispatch, peep, currentTermNo]);

  const handleCreateAndDirectToAssessment = React.useCallback(() => {
    handleSubmitExperience();
    history.push(assessmentListPath);
  }, [history, handleSubmitExperience]);

  const handleDirectToAssessment = React.useCallback(() => {
    history.push(assessmentListPath);
  }, [history]);

  useIonViewWillEnter(() => {
    dispatch(peep(fetchAssessment()));
  });

  return (
    <IonPage>
      <ModalConfirm
        isOpen={showConfirmModal}
        message={`${currentTermNo}回目の入力を終了しますか？\n現在入力中の記録を保存して、新しい記録を追加します。※現在の記録は編集できなくなります。`}
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => setShowConfirmModal(false),
          },
          {
            title: "OK",
            type: "Success",
            action: () => {
              handleCreateAndDirectToAssessment();
              setShowConfirmModal(false);
            },
          },
        ]}
      />
      <LoginHeader title="自己評価ツール" unreadNotiCount={0} />
      <IonContent className={common.wrapper}>
        <div className={clsx(common.container, styles.container)}>
          <p className={styles.title}>あなたの経験を記録してみましょう。</p>
          <p className={styles.desc}>
            あなたの素養を可視化し、将来に向けて必要なステップを考えるサポートをします。
          </p>
          {currentTermNo > 0 && (
            <div className={styles.pieChartWrapper}>
              <div className={styles.pieChart}>
                <PieChart width={60} height={60}>
                  <Pie
                    data={unansweredScoreRate}
                    dataKey="value"
                    innerRadius={25}
                    outerRadius={30}
                    startAngle={90}
                    endAngle={-270}
                    strokeWidth={0}
                  >
                    {unansweredScoreRate.map(rateData => (
                      <Cell key={rateData.name} fill={rateData.colorCode} />
                    ))}
                  </Pie>
                </PieChart>
                <p className={styles.rate}>
                  <span className={styles.count}>{answeredScoreLength}</span>
                  <span className={styles.line} />
                  <span className={styles.length}>
                    {assessmentStatusLength}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div className={styles.buttonWrapper}>
            {currentTermNo > 0 && (
              <button
                className={styles.button}
                onClick={handleDirectToAssessment}
              >
                続きを編集する
              </button>
            )}
            {!hasSameDayAssessment && (
              <button
                className={styles.button}
                onClick={() =>
                  currentTermNo > 0
                    ? setShowConfirmModal(true)
                    : handleCreateAndDirectToAssessment()
                }
              >
                新しく記録する
              </button>
            )}
          </div>
          <Link className={styles.refers} to={assessmentReferencePath}>
            自己評価ツールとは
          </Link>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AssessmentDescriptionPage;
