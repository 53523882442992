import React, { useCallback } from "react";
import {
  IonMenu,
  IonContent,
  IonIcon,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonAvatar,
  IonText,
  IonBadge,
} from "@ionic/react";
import { menuController } from "@ionic/core/components";
import {
  personCircle as personIcon,
  chevronForwardOutline as rightArrowIcon,
  close as closeIcon,
  informationCircleOutline as releaseNoteIcon,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";

import {
  Context,
  logout,
  editProfilePath,
  editCategoriesPath,
  assessmentDescriptionPath,
  notificationListPath,
  timelinePastPath,
  updateReadGearTutorial,
  updateReadGearReleaseNote,
  commentNotificationsPath,
  pastRealizationFeedbackPath,
  updateReadReleaseAds,
  submitPulseSurveyAnswers,
  submitDeclinePulseSurveyAnswers,
} from "../../../store/student";
import InstructionModal from "../InstructionModal/index";
import ReleaseAdModal from "../ReleaseAdModal/index";
import ModalConfirm from "../../ModalConfirm";
import SurveyRegisterConfirmModal from "../SurveyRegisterConfirmModal";
import SurveyAnswerModal from "../SurveyAnswerModal";
import { IPulseSurveyAnswer } from "../../../state";
import SurveyAnswerCompleteModal from "../SurveyAnswerCompleteModal";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

import bellIcon from "./../../../assets/icons/bell.svg";
import noticeIcon from "./../../../assets/icons/notice.svg";
import settingIcon from "./../../../assets/icons/setting.svg";
import categoryIcon from "./../../../assets/icons/category.svg";
import editHumanIcon from "./../../../assets/icons/edit_human.svg";
import gearIcon from "./../../../assets/icons/gear.svg";
import previousGroupIcon from "./../../../assets/icons/previous_group.svg";
import logoutIcon from "./../../../assets/icons/logout.svg";
import feedbackIcon from "./../../../assets/icons/feedback.svg";
import styles from "./SideMenu.module.scss";

const SideMenu = () => {
  const KEY_WALKTHROUGH = "walkthrough";
  const { contextState, dispatch } = React.useContext(Context);
  const [_calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [isShowTutorial, setIsShowTutorial] = React.useState(false);
  const [isShowReleaseAd, setIsShowReleaseAd] = React.useState(false);
  const [
    isShowSurveyRegisterConfirmModal,
    setIsShowSurveyRegisterConfirmModal,
  ] = React.useState(false);
  const [isShowSurveyAnswerModal, setIsShowSurveyAnswerModal] =
    React.useState(false);
  const [isShowSurveyAnswerCompleteModal, setIsShowSurveyAnswerCompleteModal] =
    React.useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [pulseSurveyAnswers, setPulseSurveyAnswers] = React.useState<
    IPulseSurveyAnswer[]
  >([]);

  const logoutFunc = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      !(contextState.student.read_gear_tutorials || []).length &&
      !!contextState.student.identity
    ) {
      setIsShowTutorial(true);
      menuController.swipeGesture(false);
    } else if (!!contextState.student.identity) {
      const hasKey =
        contextState.student.read_gear_tutorials.includes(KEY_WALKTHROUGH);
      setIsShowTutorial(!hasKey);
      menuController.swipeGesture(hasKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.student.read_gear_tutorials]);

  React.useEffect(() => {
    if ((contextState.student.unread_release_ads || []).length > 0) {
      setIsShowReleaseAd(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.student.unread_release_ads]);

  React.useEffect(() => {
    if (pulseSurveyAnswers.length === 0) {
      const anwsers = contextState.pulse_survey_questions.map(
        pulse_survey_question => {
          return {
            pulse_survey_question_id: pulse_survey_question.id,
            score: undefined,
            reason: "",
          };
        },
      );

      setPulseSurveyAnswers(anwsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.pulse_survey_questions]);

  React.useEffect(() => {
    setIsShowSurveyRegisterConfirmModal(
      contextState.student.available_pulse_survey_answers,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.student.available_pulse_survey_answers]);

  const closeModalTutorial = () => {
    const hasKey =
      contextState.student.read_gear_tutorials.includes(KEY_WALKTHROUGH);
    !hasKey &&
      dispatch(
        updateReadGearTutorial([
          ...contextState.student.read_gear_tutorials,
          KEY_WALKTHROUGH,
        ]),
      );
    setIsShowTutorial(false);
    menuController.swipeGesture(true);
  };

  const handleReadReleaseAdsFunc = (release_ad_ids: number[]) => {
    dispatch(updateReadReleaseAds(release_ad_ids));
  };

  React.useEffect(() => {
    isShowTutorial && menuController.swipeGesture(false);
  }, [isShowTutorial]);

  const updateReadGearReleaseNoteFunc = useCallback(() => {
    dispatch(updateReadGearReleaseNote());
  }, [dispatch]);

  const submitAnswers = () => {
    dispatch(peep(submitPulseSurveyAnswers(pulseSurveyAnswers)));
    setIsShowSurveyAnswerModal(false);
    setIsShowSurveyAnswerCompleteModal(true);
  };

  const handleDeclineAnswers = () => {
    dispatch(peep(submitDeclinePulseSurveyAnswers()));
    setIsShowSurveyAnswerModal(false);
  };

  return (
    <IonMenu
      contentId="sideMenuContainer"
      swipeGesture={contextState.sidebarSwipe}
    >
      <ModalConfirm
        isOpen={isShowConfirm}
        message="ログアウトしますか？"
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => setIsShowConfirm(false),
          },
          {
            title: "ログアウト",
            type: "Success",
            action: logoutFunc,
          },
        ]}
      />
      <InstructionModal
        isOpen={isShowTutorial}
        closeModal={closeModalTutorial}
      />
      {(contextState.student.unread_release_ads || []).length > 0 && (
        <ReleaseAdModal
          isOpen={isShowReleaseAd}
          release_ads={contextState.student.unread_release_ads}
          closeModal={() => setIsShowReleaseAd(false)}
          readReleaseAds={handleReadReleaseAdsFunc}
        />
      )}
      {contextState.pulse_survey_enabled && (
        <SurveyRegisterConfirmModal
          isOpen={isShowSurveyRegisterConfirmModal}
          closeModal={() => {
            handleDeclineAnswers();
            setIsShowSurveyRegisterConfirmModal(false);
          }}
          showSurveyAnswerModel={() => {
            setIsShowSurveyRegisterConfirmModal(false);
            setIsShowSurveyAnswerModal(true);
          }}
        />
      )}
      <SurveyAnswerModal
        isOpen={isShowSurveyAnswerModal}
        currentQuestionIndex={currentQuestionIndex}
        pulseSurveyQuestions={contextState.pulse_survey_questions}
        pulseSurveyAnswers={pulseSurveyAnswers}
        openConfirmModal={() => {
          setCurrentQuestionIndex(0);
          setIsShowSurveyRegisterConfirmModal(true);
          setIsShowSurveyAnswerModal(false);
        }}
        setPulseSurveyAnswers={(current_index, score, reason) => {
          setPulseSurveyAnswers(
            pulseSurveyAnswers.map((answer, index) =>
              current_index === index ? { ...answer, reason, score } : answer,
            ),
          );
        }}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
        closeModal={() => {
          handleDeclineAnswers();
          setCurrentQuestionIndex(0);
          setIsShowSurveyAnswerModal(false);
        }}
        submitAnswers={submitAnswers}
      />
      <SurveyAnswerCompleteModal
        isOpen={isShowSurveyAnswerCompleteModal}
        closeModal={() => {
          setIsShowSurveyAnswerCompleteModal(false);
        }}
      />
      <IonContent className={styles.wrapper}>
        <IonItemGroup>
          <IonItem className={styles.sideMenuItemTop}>
            <IonIcon
              icon={closeIcon}
              slot="end"
              color="light"
              className={styles.icon}
              onClick={() => menuController.close()}
            />
          </IonItem>
          <div>
            {contextState.student.photo?.url ? (
              <IonAvatar
                slot="start"
                color="light"
                className={`${styles.avatar} ${styles.photo}`}
              >
                <img
                  alt="プロフィール画像"
                  src={contextState.student.photo.url}
                />
              </IonAvatar>
            ) : (
              <IonIcon
                className={styles.avatar}
                icon={personIcon}
                slot="start"
                color="light"
              />
            )}
            <IonText color="light">
              <div
                className={styles.className}
              >{`${contextState.class_name}`}</div>
            </IonText>
            <IonText color="light">
              <div
                className={styles.name}
              >{`${contextState.student.last_name} ${contextState.student.first_name}`}</div>
            </IonText>
            <IonText color="light">
              <div
                className={styles.identity}
              >{`${contextState.student.identity}`}</div>
            </IonText>
          </div>
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => {
              history.push(commentNotificationsPath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={bellIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light">通知</IonLabel>
            {contextState.unread_comments_count > 0 && (
              <IonBadge color="danger">
                {contextState.unread_comments_count}
              </IonBadge>
            )}
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => {
              history.push(notificationListPath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={noticeIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />

            <IonLabel color="light">お知らせ</IonLabel>
            {contextState.unread_notification_count > 0 && (
              <IonBadge color="danger">
                {contextState.unread_notification_count}
              </IonBadge>
            )}
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => {
              history.push(editProfilePath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={settingIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />

            <IonLabel color="light">プロフィール編集</IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => {
              history.push(editCategoriesPath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={categoryIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />

            <IonLabel color="light">活かし先設定</IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => {
              setIsShowTutorial(true);
            }}
          >
            <IonIcon
              icon={gearIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light">GEARについて</IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => {
              history.push(assessmentDescriptionPath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={editHumanIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />

            <IonLabel color="light">自己評価ツール</IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => {
              history.push(pastRealizationFeedbackPath);
              menuController.close();
            }}
          >
            <IonIcon
              icon={feedbackIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light">過去のフィードバック</IonLabel>
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          {contextState.has_left_classes && (
            <IonItem
              className={styles.sideMenuItem}
              onClick={() => {
                history.push(timelinePastPath);
                menuController.close();
              }}
            >
              <IonIcon
                icon={previousGroupIcon}
                slot="start"
                color="light"
                className={styles.icon}
              />
              <IonLabel color="light">以前の所属グループ</IonLabel>
              <IonIcon
                icon={rightArrowIcon}
                slot="end"
                className={styles.arrowIcon}
              />
            </IonItem>
          )}
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => {
              window.open(
                process.env.REACT_APP_STUDENT_RELEASE_NOTE_URL,
                "_blank",
              );
              if (!contextState.student.read_gear_release_note) {
                updateReadGearReleaseNoteFunc();
              }
            }}
          >
            <IonIcon
              icon={releaseNoteIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light">アップデート情報</IonLabel>
            {!contextState.student.read_gear_release_note && (
              <IonBadge color="danger">1</IonBadge>
            )}
            <IonIcon
              icon={rightArrowIcon}
              slot="end"
              className={styles.arrowIcon}
            />
          </IonItem>
          <IonItem
            className={styles.sideMenuItem}
            onClick={() => setIsShowConfirm(true)}
          >
            <IonIcon
              icon={logoutIcon}
              slot="start"
              color="light"
              className={styles.icon}
            />
            <IonLabel color="light">ログアウト</IonLabel>
          </IonItem>
        </IonItemGroup>
      </IonContent>
    </IonMenu>
  );
};

export default SideMenu;
