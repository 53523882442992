import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
  createGesture,
} from "@ionic/react";
import { chevronBackOutline as backIcon } from "ionicons/icons";
import React, { useRef } from "react";
import clsx from "clsx";

import { ShareRealization } from "../../state";
import CommentModal from "../../atoms/CommentModal";
import ShareRealizationContent from "../../molecules/ShareRealizationContent";
import ShareRealizationUserIcon from "../../assets/icons/share_realization_user_icon.svg";

import styles from "./TimelineEntryShareRealizationDetailModal.module.scss";

export interface TimelineEntryDetailModalProps {
  showDetail: boolean;
  shareRealization: ShareRealization | undefined;
  isOtherStudent: boolean;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  addDerivative: () => void;
  onClose: () => void;
}

const TimelineEntryShareRealizationDetailModal: React.FC<
  TimelineEntryDetailModalProps
> = ({
  showDetail,
  shareRealization,
  isOtherStudent,
  addDerivative,
  addFavorite,
  removeFavorite,
  onClose,
}) => {
  const onClickDerivative = () => {
    addDerivative !== undefined && addDerivative();
  };

  const contentRef = useRef<HTMLDivElement>(null);

  const onClickFavorite = () => {
    if (shareRealization === undefined)
      throw new Error("shareRealization is undefined");
    if (shareRealization.is_favorite) {
      removeFavorite !== undefined && removeFavorite(shareRealization.id);
      return;
    }
    addFavorite !== undefined && addFavorite(shareRealization.id);
  };

  React.useEffect(() => {
    const swipeElement = contentRef.current;
    const SWIPE_THRESHOLD = 50;

    if (swipeElement) {
      const gesture = createGesture({
        el: swipeElement,
        gestureName: "swipe-to-close",
        direction: "x",
        canStart: detail => {
          const target = detail.event.target as HTMLElement;
          // 'disable-gesture' クラスを持つ要素内ではジェスチャーを開始しない
          // 該当のテキストのみ適応するため子コンポーネントで 'disable-gesture' クラスを適用しています
          return !target.closest(".disable-gesture");
        },
        onMove: detail => {
          const { deltaX } = detail;

          if (deltaX > SWIPE_THRESHOLD) {
            onClose();
          }
        },
      });

      gesture.enable();

      return () => {
        gesture.destroy();
      };
    }
  }, [onClose]);

  return (
    <CommentModal
      isOpen={showDetail}
      onClickBackdrop={() => {
        onClose();
      }}
    >
      <div ref={contentRef}>
        <IonContent className={clsx(styles.wrapper)}>
          <div className={styles.contentComment}>
            <IonHeader className={styles.header} mode="ios">
              <IonToolbar className={styles.toolBar}>
                <IonTitle className={styles.title}>詳細</IonTitle>
                <IonButtons slot="start">
                  <IonButton
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <IonIcon icon={backIcon} color="light" />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <div className={clsx(styles.realizationItem)}>
              <div className={styles.avatarArea}>
                <IonAvatar color="light" className={styles.avatar}>
                  <IonImg
                    alt="プロフィール画像"
                    src={ShareRealizationUserIcon}
                  />
                </IonAvatar>
              </div>
              {shareRealization !== undefined && (
                <IonGrid>
                  <IonRow className={styles.row}>
                    <IonCol>
                      <IonLabel color="light">
                        <span className={styles.name}>
                          {shareRealization.student.full_name}
                        </span>
                      </IonLabel>
                    </IonCol>
                    <IonCol size="12" className={styles.contentWrapper}>
                      <ShareRealizationContent
                        shareRealization={shareRealization}
                        derivable={isOtherStudent}
                        onClickDerivative={onClickDerivative}
                        onClickFavorite={onClickFavorite}
                        isDetail={true}
                        disableGesture={true}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
            </div>
          </div>
        </IonContent>
      </div>
    </CommentModal>
  );
};

export default TimelineEntryShareRealizationDetailModal;
