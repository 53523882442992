import { IonAvatar, IonGrid, IonIcon, IonImg, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction } from "react";
import {
  ellipsisHorizontal,
  personCircle,
  closeCircleOutline,
} from "ionicons/icons";
import clsx from "clsx";

import { convertBrAndHref } from "../../libs/Convert";
import { IComment, IFeedback, IOnClickStudentAvatarParams } from "../../state";
import { simpleDateTimeStr } from "../../libs/Util";
import CommentConfig from "../../atoms/CommentConfig";
import { timelinePastPath } from "../../store/student";
import { loadStamp } from "../../libs/stamp";

import styles from "./CommentItem.module.scss";

export interface CommentItemProps {
  calling?: boolean;
  comment: IComment | IFeedback;
  current_student_id?: number;
  current_teacher_id?: number;
  showCommentConfigID: number;
  disableGesture?: boolean;
  showDialog?: (id: number) => void;
  delete: (id: number) => void;
  update: (args: { [key: string]: any }) => void;
  updateShowCommentConfigID: Dispatch<SetStateAction<number>>;
  onAvatarClick?: (params: IOnClickStudentAvatarParams) => void;
}

const CommentItem = (props: CommentItemProps) => {
  const isTimelinePast = window.location.pathname.includes(timelinePastPath);
  const isTeacherApp = window.location.pathname.includes("teacher");
  const onAvatarClick = props.onAvatarClick;

  const stamp = loadStamp(props.comment.realization_stamp_reaction?.id);

  const handleClickAvatar = React.useCallback(
    e => {
      const { student_id, name, photo, klass_id, klass_name } = props.comment;
      if (!onAvatarClick || !student_id) return;
      e.stopPropagation();

      onAvatarClick({
        student_id,
        name,
        photo,
        class_id: klass_id,
        class_name: klass_name,
      });
    },
    [onAvatarClick, props.comment],
  );

  return (
    <div
      className={clsx(
        styles.wrapper,
        isTeacherApp && styles.styleTeacherApp,
        isTimelinePast && styles.wrapper__past,
      )}
    >
      <IonGrid>
        <IonRow>
          {props.comment.student_id === props.current_student_id ||
          props.comment.teacher_id === props.current_teacher_id ? (
            <div className={styles.currentCommentWrapper}>
              {props.showCommentConfigID === props.comment.id && (
                <CommentConfig
                  calling={props.calling}
                  editable={!stamp}
                  copyable={!stamp}
                  comment={props.comment}
                  delete={props.delete}
                  update={props.update}
                  updateShowCommentConfigID={props.updateShowCommentConfigID}
                />
              )}
              {!props.comment.is_system && (
                <IonIcon
                  slot="end"
                  icon={
                    props.showCommentConfigID === props.comment.id
                      ? closeCircleOutline
                      : ellipsisHorizontal
                  }
                  className={styles.configIcon}
                  onClick={() =>
                    props.updateShowCommentConfigID(
                      props.showCommentConfigID === props.comment.id
                        ? 0
                        : props.comment.id,
                    )
                  }
                />
              )}
              <div
                className={clsx(
                  styles.currentContent,
                  stamp && styles.stampCommentArea,
                  props.comment.is_system && styles.systemComment,
                  // gestureでモーダルを閉じる動作をいれてるいる。コピペができるようにテキストは対象外にしている。
                  props.disableGesture && "disable-gesture",
                )}
              >
                {stamp ? (
                  <div className={styles.myStampWrapper}>
                    <IonImg src={stamp.image} className={styles.stamp} />
                  </div>
                ) : (
                  convertBrAndHref(props.comment.content)
                )}
              </div>
              <div className={styles.currentInfo}>
                {props.comment.created_at.getTime() <
                props.comment.updated_at.getTime()
                  ? "編集済み " + simpleDateTimeStr(props.comment.created_at)
                  : simpleDateTimeStr(props.comment.created_at)}
              </div>
            </div>
          ) : (
            <>
              <div
                className={clsx(
                  styles.avatarArea,
                  !!props.comment.student_id &&
                    !!onAvatarClick &&
                    styles.clicableAvatar,
                )}
                onClick={handleClickAvatar}
              >
                {props.comment.photo?.url ? (
                  <IonAvatar
                    slot="start"
                    color="light"
                    className={styles.avatar}
                  >
                    <img alt="プロフィール画像" src={props.comment.photo.url} />
                  </IonAvatar>
                ) : (
                  <IonIcon
                    className={styles.avatar}
                    icon={personCircle}
                    slot="start"
                    color="light"
                  />
                )}
              </div>
              <div className={styles.wrapperMsg}>
                <div
                  className={clsx(
                    styles.commentArea,
                    stamp && styles.stampCommentArea,
                    !props.comment.name && styles.anonymous,
                  )}
                >
                  <div className={styles.name}>
                    {props.comment.name && (
                      <span>{`${props.comment.name}`}</span>
                    )}
                    {props.showDialog &&
                      !props.comment.teacher_id &&
                      !props.comment.is_system && (
                        <IonIcon
                          slot="end"
                          icon={ellipsisHorizontal}
                          className={styles.reportIcon}
                          onClick={() =>
                            props.showDialog &&
                            props.showDialog(props.comment.id)
                          }
                        />
                      )}
                  </div>
                  <div
                    className={clsx(
                      styles.content,
                      props.comment.is_system && styles.systemComment,
                      // gestureでモーダルを閉じる動作をいれてるいる。コピペができるようにテキストは対象外にしている。
                      props.disableGesture && "disable-gesture",
                    )}
                  >
                    {stamp ? (
                      <div className={styles.otherStampWrapper}>
                        <IonImg src={stamp.image} className={styles.stamp} />
                      </div>
                    ) : (
                      convertBrAndHref(props.comment.content)
                    )}
                  </div>
                </div>
                <div className={styles.date}>
                  {props.comment.created_at.getTime() <
                  props.comment.updated_at.getTime()
                    ? "編集済み " + simpleDateTimeStr(props.comment.created_at)
                    : simpleDateTimeStr(props.comment.created_at)}
                </div>
              </div>
            </>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default CommentItem;
